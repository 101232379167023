// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  SALESFORCE_BASE_URL: ' https://abbott-ph-api-dev.sttarter.com/api/v1',
  
  API_URl_TRACEBILITY: 'https://abbott-ph-api-dev.sttarter.com/api',
  IMAGE_UPLOAD: 'https://apac-apigateway.capillarytech.com/mobile/v2/',
  EMAIL_CHECK_API: 'https://abbott-ph-api-dev.sttarter.com/api/v1',
  CMS_URL:
    'https://api.sttarter.com/contentsystem/d3873b72c421a8f2df3952a1d5ce51fa',
  STTARTER_GAMEPLAY_REWARD:
    'https://api.sttarter.com/contentsystem/d3873b72c421a8f2df3952a1d5ce51fa',
  STTARTER_GAMEPLAY_FIRSTTIME_SCAN:
    'https://api.sttarter.com/contentsystem/d3873b72c421a8f2df3952a1d5ce51fa/firsttimescanuat',
  STTARTER_GAMEPLAY_LUCKY_DRAW:
    'https://api.sttarter.com/contentsystem/d3873b72c421a8f2df3952a1d5ce51fa/luckydrawuat',
  // GAME_MAP_API: "https://cap-intouch-in.sttarter.com",
  GAME_MAP_API: 'https://cap-apac-api-com.sttarter.com/v1.1',
LISCENSEKEY:"AXUieAHLI6D8CuynbiLcax8ub58lCtU2p0MDtUxhfvW0FwhlcEyKLBpV5/mIW3u8s2SjUlksnKSkQHE8Qid9C75Te72vQGUGhEdvTRB/xOEAbGmHtFAj4gtazHFPCoARbCjxudkiKSQSM+K17wn9/oNwFbM9be+LwCH41LZSFoowTUNYfkRtU/hxUTl6Tr9cjUULpSd0Yiz6NafaakX0eYhj1aK7Y+PlkmN4G7xQAiWhc/IbRGSY0uxrXdmxLLqh+RF/vX0vxQQQJ6hBnHbIv/pA1Hs7FF57BEDi7gpvHjTXV1h73goFjXpqDUFqN8iTP21BeBlpHI5zQLMIWgZtL1Ygjru/ZmWNnGHh+4B0F4mYW97C2F//j0Ev1bjJKD8RsnJsGQor6OcxSjDUkkpyaTxsFgzbUUAJC2GGoElKaX4vf0l73m61MgRwnpWBLfvulBtWTCB0AUEPV+Dmxj8ORWsnZnyHVBgUt1CIAXZE7ZyEoMT4M/RHYEthK4oJdmXj5H+ur61FX2tHiutkNMxjR41/sUZbDo9/uSI9GfBRP8W2jnzszbDU3aIeTylcSN8boBeltUBV4jnPwFjaZa5d/n7+bIfRO7zmr9n+8wJwZafc7++RmAt7Gq6QcVt98eI5TVgWmKzHid80BwoT5WeWQ2ky69e5qCx9C0Bc9sx1axy7gjvV9vK86/ba9p+pG6HEeTpI0S2gbhyI4aHctiQrULC7eYnHMHj90u3VpKUwsXFr12ra0iopt55f7bFCRDfxeTlZBP9/XOXfL7jXAQKcTJp5snSl4/NFNwMJ1AOz+UKD0yFOfr3wAcBGFim7QjWAfiv1kOAQ6qT2YFycC4EWYl1nDSW5SaYRSuatA0upQcgMFXpm7Qua4U8P53K6wjOvG/PJ8f6Hlu3wiNG6x6R8DZ4+JfkL3JohZ/2pYYel9LY2rIh7lFB+YyNhbnxw02vXLclLZlXAqeZeSGsEAcbAtqUPGrYm6pTVcXJ25ARxPSmu+HlgJtK2lJL8Zz0fB5rb0KpBeRbU6tLHhRhaCSaKD958IC/p6Dxyc6PfoOR3QE/b1TbELyPurOjfWqTSY9cFocyTNTMIKqyNEis/8EE7/nkAvG9VngNXZ5cQElWnCmjr8BqwU970PZGp8nCIm25GbAJklFBZR9DkLhvjkLNmLQ==",
  NUTRITION_CUST_ADD_URL: 'https://cap-intouch-in.sttarter.com',
  NUTRITION_TILL_ID: 'nutrition',
  //OAUTH_TOKEN:'https://cap-api-eu.sttarter.com/v3',
  OAUTH_TOKEN:'https://cap-apac-api-com.sttarter.com/v3',
  SOCIAL_LOGIN_URL: 'https://cap-s3-apac.sttarter.com',
  WRAPPER_MIDDLEWARE_API: "https://abbott-ph-api-dev.sttarter.com/api/v1",
  
  
  BRAND: 'ABBOTTDEMO',
  DEVICE_ID: '1234551231',
  IDENTIFIER_TYPE: 'MOBILE',
  TILL_ID: 'abbottindonesia',
  COUNTRY_CODE: 'ID',
  LANGUAGE: 'en_US',
  GAME_LOYALTY_POINTS: 300,
  POINTS_REDEEMED: 300,
  bugsnag_version: '10112023',
  substring: 0,
  MOBILE_APP_KEY: "85a4447a3b438fbb6e33e5c0428460d3"


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
